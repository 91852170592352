<!-- @Author: Yu_Bo date -->
<template>
  <div class='swiper_list'>
    <div class="img_top" v-if="list.length">
      <el-image style="width: 100%; height: 100%" :src="list[listIndex].img" fit="scale-down"></el-image>
      <div class="top_num">{{listIndex+1}}/{{list.length}}</div>
    </div>
    <div class="wrapper" v-if="list.length">
      <swiper ref="mySwiper" v-bind:options="swiperOptions">
        <swiper-slide v-for="(item, index) in list" v-bind:key="index">
          <el-image style="width: 100%; height: 100%" :src="item.img" fit="cover" @click="imgBtn(index)"></el-image>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
 import { swiper, swiperSlide } from "vue-awesome-swiper";
 export default {
   components: {
     swiper,
     swiperSlide
   },
    props: {
      list: {
        type: Array,
        default: []
      }
    },
    data() {
      return{
        swiperOptions: {
          slidesPerView: 5,
          spaceBetween: 10,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
        //
        listIndex:0,
        // list:[
        //   {img:require('@/assets/images/verify_img/images_a.png')},
        //   {img:require('@/assets/images/verify_img/images_b.png')},
        //   {img:require('@/assets/images/verify_img/images_c.png')},
        //   {img:require('@/assets/images/verify_img/images_d.png')},
        //   {img:require('@/assets/images/verify_img/images_e.png')},
        //   {img:require('@/assets/images/verify_img/images_f.png')},
        //   {img:require('@/assets/images/verify_img/images_g.png')},
        //   {img:require('@/assets/images/verify_img/images_h.png')},
        //   {img:require('@/assets/images/verify_img/images_i.png')},
        //   {img:require('@/assets/images/verify_img/images_j.png')},
        // ]
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 切换
      imgBtn(index){
        this.listIndex=index
      },
    },
  }
</script>

<style lang='scss' scoped>
  .swiper_list{
    width: 500px;
    .img_top{
      position: relative;
      width: 100%;
      height: 500px;
      background: #f7f7f7;
      .top_num{
        position: absolute;
        bottom: 0;
        right: -56px;
        width: 46px;
        height: 20px;
        line-height: 20px;
        background: rgba(0,0,0,0.08);
        border-radius: 10px;
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .wrapper {
      position: relative;
      width: 100%;
      margin-top: 10px;
      .swiper-slide {
        cursor: pointer;
        width: 92px;
        height: 92px;
        background: #f00;
      }
      .swiper-button-next:after, .swiper-button-prev:after{
        content:"";
      }
      .swiper-button-next, .swiper-button-prev{
        width: 20px;
        height: 20px;
        margin-top: -10px;
      }
      .swiper-button-prev{
        background: url(~@/assets/images/icon/icon_left.png) no-repeat;
        background-size: 100% 100%;
      }
      .swiper-button-next{
        background: url(~@/assets/images/icon/icon_right.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
</style>
